import Vue from 'vue'
import * as t from '../mutations'
import http from '@/http'
import { clone } from 'lodash'
import { CLIENT_DOMAINS } from '@/api/v3/endpoints'
import * as Cookies from 'js-cookie'

export const RECORD_TYPE_MAPPING = {
  A: 'external_host',
  AAAA: 'external_host',
  MX: 'mx',
  TXT: 'txt',
  CNAME: 'subdomain',
  NS: 'ns',
  SRV: 'srv',
  CAA: 'caa',
  nameserver: 'nameserver',
  glue_record: 'glue_record',
}

export const IPV6_REGEX = `(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|` +
  `([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|` +
  `([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|` +
  `([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|` +
  `[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|` +
  `fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|` +
  `1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|` +
  `([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|` +
  `(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))`
export const IPV4_REGEX = `^(?:[0-9]{1,3}.){3}[0-9]{1,3}$`

const getDefaultState = () => {
  return {
    domain: null,
    domainSsl: null,
    webHosting: {},
    tlds: null,
    hostingTransferInfo: null,
    standaloneHostingInfo: null,
    domainTransferRequests: [],
    domainWhois: {},
    selectedDomainRecord: null,
    requestInProgress: false,
    selectedDomain: null,
    domainSearch: null,
    domainSearchAvailable: false,
    domainSearchResults: null,
    domainsForAccount: null,
    domainAvailability: {},
  }
}
const STATE = getDefaultState()

const GETTERS = {
  domain: state => state.domain,
  domainsForAccount: state => state.domainsForAccount,
  domainAvailability: state => state.domainAvailability,
  domainSsl: state => state.domainSsl,
  domainHostingInfo: (_state, getters) => getters.domain?.domain_hosting_info || null,
  webHosting: state => state.webHosting,
  tlds: state => state.tlds,
  hostingTransferInfo: state => state.hostingTransferInfo,
  standaloneHostingInfo: state => state.standaloneHostingInfo,
  activeDomainTransferRequest: state => state.domainTransferRequests.find(hti =>
    !hti.status.toLowerCase().includes('cancel') && !hti.status.toLowerCase().includes('expired')),
  domainTransferRequests: state => state.domainTransferRequests,
  domainSearch: state => state.domainSearch,
  domainSearchResults: state => state.domainSearchResults,
  domainSearchAvailable: (_, getters) => !!getters.domainSearchResults?.some(value =>
    value?.available && value?.domain === getters.domainSearch
  ),
  selectedDomain: state => state.selectedDomain,
  domainRecords: (_, getters) => getters.domain.records.map(record => {
    return { ...record, record_type: RECORD_TYPE_MAPPING[record.type] }
  }),
  domainWhois: state => state.domainWhois,
  externalHosts: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'external_host'),
  txtRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'txt'),
  subdomains: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'subdomain'),
  nsRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'ns'),
  srvRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'srv'),
  caaRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'caa'),
  mxRecords: (_, getters) => getters.domainRecords.filter(r => r.record_type === 'mx'),
  nameservers: (_, getters) => getters.domain.nameservers,
  selectedDomainRecord: (state) => state.selectedDomainRecord,
  showAdminTools:        () => {
    const adminCookie = Cookies.get('admin') === 'logged_in_as_client'
    const sessionFlag = sessionStorage.getItem('admin-logged-in')
    const domainAdminFlag = sessionStorage.getItem('domain-admin')

    return adminCookie && sessionFlag && domainAdminFlag
  },
}

const ACTIONS = {
  // Domains ---------------------------------------------------------------------------------------
  async swapDomain({ dispatch }, { id, sourceDomain, targetDomain }) {
    try {
      const params = {
        source_domain: sourceDomain,
        target_domain: targetDomain,
      }

      const domainResult = await http.put(`client/domains/${id}/swap`, params)

      if (domainResult.data.success) {
        const domainSwapped = domainResult.data.response
        dispatch('dashpanel/swapDomain', domainSwapped, { root: true })
        return domainResult.data
      }
    } catch(error) {
      return error
    }
  },
  async renewDomain({ _ }, { domain, autopay_id }) {
    try {
      const params = {
        payable_id: autopay_id,
      }

      return await http.post(`client/domains/${domain.id}/renewal`, params)
    } catch(error) {
      return error
    }
  },
  async getRenewalPrice({ _ }, { domain }) {
    try {
      const domainResult = await http.get(`client/domains/${domain.id}/renewal`)

      if (domainResult.data.success) {
        return domainResult.data.response
      }
    } catch(error) {
      return error
    }
  },
  async createDomain({ commit, getters, dispatch }, company_id) {
    try {
      const params = {
        domain_name: getters.selectedDomain,
        company_id: company_id,
        use_info_on_file: 'true',
        privacy: 'true',
      }
      const domainResult = await http.post(`client/domains`, params)

      if (domainResult.data.success) {
        const domainCreated = domainResult.data.response
        commit(t.SET_DOMAIN, domainCreated)
        dispatch('dashpanel/addDomain', domainCreated, { root: true })
        return domainResult.data
      }
    } catch(error) {
      return error
    }
  },
  async setWhois({ commit, _getters }, { id, newWhois, privacy }) {
    const domainRes = await http.put(`client/domains/${id}/whois`, { whois: newWhois, private: privacy })
    const domain = domainRes.data.response

    commit(t.SET_DOMAIN, domain)
  },
  async addService({ _ }, { domainId }) {
    try {
      const addResult = await http.post(`client/domains/${domainId}/addons`)

      if (addResult.data.success) {
        return { success: true }
      }

    } catch(error) {
      return { success: false, error: error }
    }
  },
  async createHosting({ commit }, domainId) {
    try {

      const hostingResult = await http.post(`client/domains/${domainId}/hosting`)

      if (hostingResult.data.success) {
        commit(t.SET_WEB_HOSTING, hostingResult.data.response)
        return { success: true }
      }

    } catch(error) {
      return { success: false, error: error }
    }
  },
  async reinstallWordpress({ _ }, id ) {
    return await http.post(`client/domains/${id}/hosting/reinstall`)
  },
  async createSSL({ getters }, { data }) {
    try {
      const domainId = getters.domain.id
      const result = await http.post(`client/domains/${domainId}/domain_ssl`, {
        domain_id: domainId,
        data: {
            default: data,
        },
      })
      return { success: result.data.success }
    } catch(error) {
      return { success: false, error: error }
    }
  },
  async revalidateSsl({ getters }, cert_order_id) {
    try {
      const domainId = getters.domain.id
      const result = await http.put(`client/domains/${domainId}/domain_ssl`, {
        cert_order_id: cert_order_id,
      })

      return { success: result.data.success }
    } catch (error) {
      return { success: false, error: error }
    }
  },
  async fetchDomainSslDownloadUrl({ getters }, cert_order_id) {
    try {
      const domainId = getters.domain.id
      const result = await http.get(`${CLIENT_DOMAINS}/${domainId}/domain_ssl`, {
        cert_order_id: cert_order_id,
      })

      return result.data.response.s3_url
    } catch (error) {
      return { success: false, error: error }
    }
  },
  async fetchMagicLink({ _ }, companyLink) {
    const response = await http.get(companyLink)

    if (response.data.success) {
      return response.data.response.url
    }
  },
  async fetchHostingStatus({ _getters }, id ) {
    const statusRes = await http.get(`client/domains/${id}/hosting`)

    return statusRes.data.response
  },
  async fetchDomains() {
    const domainRes = await http.get(`client/domains`)
    return domainRes.data.response
  },
  async fetchDomain({ commit }, { id }) {
    // fetch domain
    const domainRes = await http.get(`client/domains/${id}`)
    const domain = domainRes.data.response

    domain.nameservers = domainRes.data.response?.domain_name_servers

    // fetch and include dns host records
    const dnsRes = await http.get(`client/domains/${id}/dns`)
    domain.records = dnsRes.data.response?.hosts

    // fetch and include dns nameservers
    const servicesRes = await http.get(`client/domains/${id}/addons`)
    domain.services = servicesRes.data.response?.result

    await ACTIONS.fetchDomainNameservers({ commit }, { domain, id })

    commit(t.SET_DOMAIN, domain)
  },
  async fetchDnsRecords({ commit }, { domain }) {
    const dnsRes = await http.get(`client/domains/${domain.id}/dns`)
    domain.records = dnsRes.data.response?.hosts

    commit(t.SET_DOMAIN, domain)
  },
  async fetchMostRecentDomainAndEmail({ commit, dispatch }, companyId) {
    const domainsResults = await http.get(`client/domains`)

    if (domainsResults.data.success && domainsResults.data.response?.length) {
      const domains = domainsResults.data.response
      const companyDomain = domains.find(d => d.company_id === companyId)
      if (companyDomain) {
        commit(t.SET_DOMAIN, companyDomain)
        dispatch('domainEmails/fetchDomainEmails', {
          domainId: companyDomain.id,
          setMostRecentDomain: true,
        }, { root: true })
      } else {
        commit(t.RESET_DOMAINS)
        dispatch('domainEmails/resetDomainEmail', null, { root: true })
      }
    }
  },
  async searchDomains({ commit, dispatch }, domainSearch) {
    if (domainSearch === null) {
      commit(t.SET_DOMAIN_SEARCH, domainSearch)
      return
    }

    const domains = [domainSearch]

    const params = {
      domains: domains,
    }

    try {
      const result = await http.post(`client/domains/search`, params)

      const tld = domainSearch.slice(domainSearch.indexOf('.'))
      const domains = result.data.response.domains.filter(domainData => domainData.available)
        .sort((a, b) => {
          // If domain matches search, push to the top of results.
          if (a.domain === domainSearch) {
            return -1
          }
          if (b.domain === domainSearch) {
            return 1
          }

          const aTld = a.domain.slice(a.domain.indexOf('.'))
          const bTld = b.domain.slice(b.domain.indexOf('.'))

          // If tld matches search tld, push to the top of results.
          if (aTld === tld) {
            return -1
          }
          if (bTld === tld) {
            return 1
          }

          // Otherwise, order by tld alphabetically, then domain alphabetically
          if (aTld < bTld) {
            return -1
          }
          if (aTld > bTld) {
            return 1
          }
          if (a.domain < b.domain) {
            return -1
          }
          if (a.domain > b.domain) {
            return 1
          }

          return 0
        })

      commit(t.SET_DOMAIN_SEARCH, domainSearch)
      dispatch('setDomainSearchResults', domains !== undefined ? domains : [])
    } catch(error) {
      return error
    }
  },
  async fetchDomainAvailability({ commit }, domainName) {
    const params = {
      domains: [domainName],
    }
    try {
      const response = await http.post(`${CLIENT_DOMAINS}/availability/check_availability`, params)
      commit(t.SET_DOMAIN_AVAILABILITY, response.data.response.domains)
    } catch(error) {
      return error
    }
  },
  async setDomain({ commit, _dispatch }, domain) {
    commit(t.SET_DOMAIN, domain)
  },
  async fetchDomainWhois({ commit }, { id, showPrivate }) {
    try {
      const params = {}
      params.private = showPrivate ? showPrivate : undefined
      const domainWhoisRes = await http.get(`${CLIENT_DOMAINS}/${id}/whois`, { params: params })
      const domainWhois = domainWhoisRes.data.response

      commit(t.SET_DOMAIN_WHOIS, domainWhois)
    } catch(error) {
      return error
    }
  },
  async fetchDomainSsl({ commit }, { id }) {
    const domainSslRes = await http.get(`${CLIENT_DOMAINS}/${id}/domain_ssl`)
    const domainSsl = domainSslRes.data.response

    commit(t.SET_DOMAIN_SSL, domainSsl)

    return domainSsl.length === 0 ? null : domainSsl[0]
  },
  async setSelectedDomain({ commit, _dispatch }, domain) {
    commit(t.SET_SELECTED_DOMAIN, domain)
  },
  async setDomainSearchResults({ commit, _dispatch }, domainSearchResults) {
    commit(t.SET_DOMAIN_SEARCH_RESULTS, domainSearchResults)
  },
  async lockDomain({ commit }, { id }) {
    const response = await http.put(`client/domains/${id}/lock?lock_action=lock`)
    commit(t.SET_DOMAIN, response.data.response)
  },
  async unlockDomain({ commit }, { id }) {
    const response = await http.put(`client/domains/${id}/lock?lock_action=unlock`)
    commit(t.SET_DOMAIN, response.data.response)
  },

  setSelectedDomainRecord({ commit }, { record }) {
    commit(t.SET_SELECTED_DOMAIN_RECORD, record)
  },

  async fetchDomainsForAccount({ commit }) {
    const response = await http.get(`client/domains`)
    commit(t.SET_DOMAINS_FOR_ACCOUNT, response.data.result)
  },

  // DNS Record CRUD -------------------------------------------------------------------------------
  async createDnsRecord({ commit }, { domainId, record }) {
    try {
      const res = await http.post(`client/domains/${domainId}/dns`, {
        record_type: RECORD_TYPE_MAPPING[record.type],
        type: record.type,
        priority: record.priority || 0,
        name: record.name,
        data: record.data,
        ttl: record.ttl,
      })

      if (res.data?.status === 200) {
        const createdRecord = res.data.response.hosts[0]
        commit(t.ADD_DOMAIN_RECORD, createdRecord)
      }

      return res
    } catch(error) {
      let errorMessage = "Creation failed."
      if (error.response?.data?.errors) {
        errorMessage = `${Object.keys(error.response?.data?.errors)[0]}: ${Object.values(error.response?.data?.errors)[0]}`
      }
      return { success: false, error: errorMessage }
    }
  },
  async updateDnsRecord({ commit }, { domainId, record }) {
    try {
      const res = await http.put(`client/domains/${domainId}/dns/${record.id}`, {
        record_type: RECORD_TYPE_MAPPING[record.type],
        type: record.type,
        priority: record.priority || 0,
        name: record.name,
        data: record.data,
        ttl: record.ttl,
      })

      if (res.data?.status === 200) {
        const updatedRecord = res.data.response.hosts[0]
        commit(t.UPDATE_DOMAIN_RECORD, updatedRecord)
      }

      return res
    } catch(error) {
      let errorMessage = "Update failed."
      if (error.response?.data?.errors) {
        errorMessage = `${Object.keys(error.response?.data?.errors)[0]}: ${Object.values(error.response?.data?.errors)[0]}`
      }
      return { success: false, error: errorMessage }
    }
  },
  async deleteDnsRecord({ commit }, { domainId, record }) {
    const res = await http.delete(`client/domains/${domainId}/dns/${record.id}`)

    if (res.data?.status === 200) {
      commit(t.DELETE_DOMAIN_RECORD, record.id)
    }

    return res
  },
  async addDomainNameserver({ getters, dispatch }, { domainId, nameserver }) {
    const nameservers = [...new Set([...getters.nameservers, nameserver])]
    return dispatch('setDomainNameservers', { domainId, nameservers })
  },
  async updateDomainNameserver({ getters, dispatch }, { domainId, index, nameserver }) {
    let nameservers = clone(getters.nameservers)
    nameservers.splice(index, 1, nameserver)
    nameservers = [...new Set([...nameservers])]
    return dispatch('setDomainNameservers', { domainId, nameservers })
  },
  async fetchDomainNameservers({ commit }, { domain }, save = false) {
    const res = await http.get(`client/domains/${domain.id}/nameservers?domain_name=${domain.domain_name}`)

    if (res.data?.status === 200) {
      domain.internet_nameservers = res.data.response
      if (save) commit(t.SET_DOMAIN, domain)
    }
  },
  async fetchTlds({ commit }) {
    const res = await http.get(`client/domains/tlds`)

    if (res.data?.status === 200) {
      commit(t.SET_TLDS, res.data.response)
    }
  },
  async deleteDomainNameserver({ getters, dispatch }, { domainId, nameserver }) {
    let nameservers = clone(getters.nameservers)
    nameservers = nameservers.filter(ns => ns !== nameserver)
    return dispatch('setDomainNameservers', { domainId, nameservers })
  },
  async setDomainNameservers({ commit }, { domainId, nameservers }) {
    const res = await http.patch(`client/domains/${domainId}/nameservers`, {
      nameservers: nameservers,
    })

    if (res.data?.status === 200) {
      commit(t.SET_DOMAIN_NAMESERVERS, nameservers)
    }

    return res
  },
  async upsertDomainGlueRecords({ getters, dispatch }, { domainId, record }) {
    const ips = [...record.ipv4, ...record.ipv6]
    const requests = []
    for (const ip of ips) {
      const rec = {
        type: 'NS',
        name: ip,
        data: record.data,
        ttl: 900,
      }

      const match = getters.nsRecords.find(r => {
        return r.name === ip && r.data === rec.data
      })

      // If there's a match, we already have the record we need, no need to create
      if (!match) {
        requests.push(dispatch('createDnsRecord', { domainId, record: rec }))
      }
    }

    return await Promise.all(requests)
  },
  // Domain transfer request------------------------------------------------------------------------
  async fetchDomainTransferRequests({ commit }, companyId) {
    const apiResponse = await http.get(`client/domains/domain_transfers/${companyId}`)
    if(apiResponse.data.success && apiResponse.data.response) {
      commit(t.SET_DOMAIN_TRANSFER_REQUESTS, apiResponse.data.response)
    }

    return apiResponse
  },
  async fetchDomainTransferRequest({ commit }, id) {
    const apiResponse = await http.get(`client/domains/domain_transfers/${id}`)
    if(apiResponse.data.success && apiResponse.data.response){
      commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, apiResponse.data.response)
    }

    return apiResponse
  },
  async submitDomainTransferRequest({ commit }, { company_id, domain_name, authorization_code, payable_id }) {
    const apiResponse = await http.post('client/domains/domain_transfers',
      {
        company_id: company_id,
        domain_name: domain_name,
        authorization_code: authorization_code,
        payable_id: payable_id,
      })
    if(apiResponse.data.success && apiResponse.data.response){
      commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, apiResponse.data.response)
    }

    return apiResponse
  },
  async cancelDomainTransferRequest({ commit }, id) {
    const apiResponse = await http.put(`client/domains/domain_transfers/${id}`, { status: "cancelled" })
    if(apiResponse.data.success && apiResponse.data.response) {
      commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, apiResponse.data.response)
    }

    return apiResponse
  },
  async acceptDomainTransferRequestFoa({ commit }, { id, foa_token }){
    const apiResponse = await http.put(`client/domains/domain_transfers/${id}`, { status: "pending", foa_token })
    if(apiResponse.data.success && apiResponse.data.response) {
      commit(t.UPDATE_DOMAIN_TRANSFER_REQUEST, apiResponse.data.response)
    }

    return apiResponse
  },

  // Hosting transfer request-----------------------------------------------------------------------
  async fetchHostingTransferRequest({ commit }, { companyId, showCredentials = false }) {
    const apiResponse = await http.get(`client/domains/hosting/hosting_transfers/${companyId}`,
      { params: { show_credentials: showCredentials } })
    if(apiResponse.data.success && apiResponse.data.response){
      const { response } = apiResponse.data
      commit(t.SET_HOSTING_TRANSFER_INFO, {
        id: response.id,
        provider: response.provider,
        companyId: response.company_id,
        username: response.username,
        password: response.password,
        requestCall: response.request_call,
        status: response.status,
        admin: response.admin,
      })
    }

    return apiResponse
  },
  async submitHostingTransferRequest({ commit }, { provider, username, password, companyId, requestCall }) {
    const apiResponse = await http.post(`client/domains/hosting/hosting_transfers`,
      {
      provider,
      username,
      password,
      company_id: companyId,
      request_call: requestCall,
    })
    if(apiResponse.data.success && apiResponse.data.response){
      const { response } = apiResponse.data
      commit(t.SET_HOSTING_TRANSFER_INFO, {
        id: response.id,
        provider: response.provider,
        companyId: response.company_id,
        username: response.username,
        password: response.password,
        requestCall: response.request_call,
        status: response.status,
        admin: response.admin,
      })
    }

    return apiResponse
  },
  async updateHostingTransferRequest({ commit, getters }, { provider, username, password, companyId, requestCall, id }) {
    const apiResponse = await http.put(`client/domains/hosting/hosting_transfers/${companyId}`,
      {
      provider,
      username: username,
      password: password,
      company_id: companyId,
      request_call: requestCall,
    })
    if(apiResponse.data.success){
      commit(t.SET_HOSTING_TRANSFER_INFO, {
        ...getters.hostingTransferInfo,
        provider,
        companyId,
        requestCall,
        id,
        username: null,
        password: null,
      })
    }

    return apiResponse
  },
  async completeHostingTransferRequest({ commit }, { companyId, domainName }) {
    const apiResponse = await http.put(`client/domains/hosting/hosting_transfers/${companyId}`,
      {
        complete_transfer: true,
        domain_name: domainName,
      })
    if(apiResponse.data.success) {
      commit(t.SET_HOSTING_TRANSFER_INFO, null)
    }

    return apiResponse
  },
  async deleteHostingTransferRequest({ commit }, { companyId }) {
    const apiResponse = await http.delete(`client/domains/hosting/hosting_transfers/${companyId}`)
    if (apiResponse.data?.status === 200) {
      commit(t.SET_HOSTING_TRANSFER_INFO, null)
    }

    return apiResponse
  },

  // Standalone hosting request-----------------------------------------------------------------------
  async fetchStandaloneHostingRequest({ commit }, { companyId }) {
    const apiResponse = await http.get(`client/domains/hosting/standalone_hosting/${companyId}`)
    if(apiResponse.data.success && apiResponse.data.result){
      const result = apiResponse.data.result
      commit(t.SET_STANDALONE_HOSTING_INFO, {
        id: result.id,
        companyId: result.company_id,
        domainName: result.domain,
        requestCall: result.request_call,
        status: result.status,
        admin: result.admin,
      })
    }

    return apiResponse
  },
  async submitStandaloneHostingRequest({ commit }, { companyId, domainName, requestCall }) {
    const apiResponse = await http.post(`client/domains/hosting/standalone_hosting`,
      {
        company_id: companyId,
        domain_name: domainName,
        request_call: requestCall,
      })
    if(apiResponse.data.success && apiResponse.data.response){
      const { response } = apiResponse.data
      commit(t.SET_STANDALONE_HOSTING_INFO, {
        id: response.id,
        companyId: response.company_id,
        domainName: response.domain,
        requestCall: response.request_call,
        status: response.status,
        admin: response.admin,
      })
    }

    return apiResponse
  },
  async updateStandaloneHostingRequest({ commit, getters }, { companyId, domainName, requestCall, id }) {
    const apiResponse = await http.put(`client/domains/hosting/standalone_hosting/${companyId}`,
      {
        company_id: companyId,
        domain_name: domainName,
        request_call: requestCall,
      })
    if(apiResponse.data.success){
      commit(t.SET_STANDALONE_HOSTING_INFO, {
        ...getters.standaloneHostingInfo,
        companyId,
        domainName,
        requestCall,
        id,
      })
    }

    return apiResponse
  },
  async completeStandaloneHostingRequest({ commit }, { companyId, domainName }) {
    const apiResponse = await http.put(`client/domains/hosting/standalone_hosting/${companyId}`,
      {
        complete_standalone_hosting: true,
        domain_name: domainName,
      })
    if(apiResponse.data.success) {
      commit(t.SET_STANDALONE_HOSTING_INFO, null)
    }

    return apiResponse
  },
  async deleteStandaloneHostingRequest({ commit }, { companyId }) {
    const apiResponse = await http.delete(`client/domains/hosting/standalone_hosting/${companyId}`)
    if (apiResponse.data?.status === 200) {
      commit(t.SET_STANDALONE_HOSTING_INFO, null)
    }

    return apiResponse
  },
}


const MUTATIONS = {
  [t.RESET_DOMAINS](state) {
    Object.assign(state, getDefaultState())
  },
  [t.SET_DOMAIN](state, domain) {
    state.domain = domain
  },
  [t.SET_DOMAINS](state, domains) {
    state.domains = domains
  },
  [t.SET_HOSTING_TRANSFER_INFO](state, hostingTransferInfo) {
    state.hostingTransferInfo = hostingTransferInfo
  },
  [t.SET_STANDALONE_HOSTING_INFO](state, standaloneHostingInfo) {
    state.standaloneHostingInfo = standaloneHostingInfo
  },
  [t.SET_DOMAIN_TRANSFER_REQUESTS](state, domainTransferRequests) {
    state.domainTransferRequests = domainTransferRequests
  },
  [t.UPDATE_DOMAIN_TRANSFER_REQUEST](state, domainTransferRequest) {
    state.domainTransferRequests = [
      domainTransferRequest,
      ...state.domainTransferRequests.filter(dtr => dtr.id !== domainTransferRequest.id),
    ]
  },
  [t.SET_DOMAIN_WHOIS](state, domainWhois) {
    state.domainWhois = domainWhois
  },
  [t.SET_DOMAIN_SSL](state, domainSsl) {
    state.domainSsl = domainSsl
  },
  [t.SET_SELECTED_DOMAIN_RECORD](state, record) {
    state.selectedDomainRecord = record
  },
  [t.ADD_DOMAIN_RECORD](state, record) {
    Vue.set(state.domain.records, state.domain.records.length, record)
  },
  [t.UPDATE_DOMAIN_RECORD](state, record) {
    const recordIndex = state.domain.records.findIndex(rec => rec.id === record.id)
    Vue.set(state.domain.records, recordIndex, record)
  },
  [t.DELETE_DOMAIN_RECORD](state, record) {
    const records = state.domain.records.filter(rec => rec.id !== record.id)
    Vue.set(state.domain, 'records', records)
  },
  [t.SET_DOMAIN_NAMESERVERS](state, nameservers) {
    Vue.set(state.domain, 'nameservers', nameservers)
  },
  [t.SET_DOMAIN_SEARCH](state, domainSearch) {
    state.domainSearch = domainSearch
  },
  [t.SET_DOMAIN_SEARCH_RESULTS](state, results) {
    state.domainSearchResults = results
  },
  [t.SET_SELECTED_DOMAIN](state, selectedDomain) {
    state.selectedDomain = selectedDomain
  },
  [t.SET_WEB_HOSTING](state, webHosting) {
    state.webHosting = webHosting
  },
  [t.SET_DOMAINS_FOR_ACCOUNT](state, domains) {
    state.domainsForAccount = domains
  },
  [t.SET_TLDS](state, tlds) {
    state.tlds = tlds
  },
  [t.SET_DOMAIN_AVAILABILITY](state, domainAvailability) {
    state.domainAvailability = domainAvailability
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
